import React from 'react';
import style from './TextFeatures.module.css';
import Container from 'react-bootstrap/Container';

const TextFeatures = ({ children }: any) => {
  return (
    <div className={style.textFeatures}>
        <Container>
            {children}
        </Container>
    </div>
  )
}

export default React.memo(TextFeatures);
