import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <Router>
      <Navigation />

      <Switch>
        <Route path="/" component={Homepage} exact />

        <Route path="/" component={PageNotFound} />
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
