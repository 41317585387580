import React, { useState } from 'react';
import {NavDropdown, Nav, Navbar} from 'react-bootstrap';
import classes from 'react-style-classes'
import style from './Navigation.module.css';
import logo from '../images/logo.png';

const Navigation = () => {
  const [offset, setOffset] = useState(0);

  document.addEventListener('scroll', () => {
    setOffset(window.pageYOffset);
  })
  return (
  <div className={classes(style.navBar, offset !== 0 && style.fixed)}>
      <Navbar className={style.wrapper} expand="lg">
      <div className={style.logo}>
        <a href="https://kontentino.com">
          <svg width="150" height="31" style={{ width: '150px', marginRight: 5 }} viewBox="0 0 150 31" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-inline-block align-top">
              <path d="M22.3327 20.4574L8.40674 20.4431C7.77745 20.4425 7.26768 19.9318 7.26827 19.3025C7.26885 18.6727 7.77979 18.1632 8.40909 18.1638L22.3351 18.1781C22.9647 18.179 23.4744 18.6894 23.4738 19.3192C23.4732 19.9488 22.9623 20.4583 22.3327 20.4574ZM8.86846 12.5566L9.55518 11.3481C9.71895 11.0602 10.0685 10.989 10.3315 11.1908L11.7551 12.2812L13.6576 13.7394L21.319 7.89492C21.5824 7.69394 21.9319 7.76514 22.0951 8.05371C22.0951 8.05371 22.0951 8.05371 22.0954 8.0543L22.4417 8.66689L22.7883 9.27978C22.9512 9.56865 22.868 9.96797 22.6031 10.1672L22.6028 10.1678L14.1363 16.5378C13.8715 16.7373 13.4379 16.7379 13.1728 16.539C13.1725 16.5384 13.1719 16.5384 13.1719 16.5384L9.05303 13.4437C8.7876 13.2442 8.70469 12.8452 8.86846 12.5566ZM26.0815 4.54277C23.347 1.80264 19.5677 0.105468 15.3908 0.101366C7.03741 0.0925769 0.258696 6.85752 0.2502 15.2109C0.245806 19.3878 1.93477 23.1706 4.66905 25.9107C7.40333 28.6503 11.1826 30.3475 15.3595 30.3519C23.7129 30.3606 30.4919 23.596 30.5007 15.2426C30.5051 11.0657 28.8161 7.28262 26.0815 4.54277Z" fill="#FFF"/>
              <path d="M36.9003 6.7998L39.544 6.80273L39.5361 14.3684L46.7016 6.81006L49.9688 6.81357L43.6857 13.2765L50.2324 21.8596L47.0083 21.8563L41.8784 15.0803L39.5329 17.485L39.5285 21.8484L36.8848 21.8458L36.9003 6.7998Z" fill="#FFF"/>
              <path d="M59.9959 16.2598V16.217C59.998 14.2178 58.5595 12.561 56.5178 12.559C54.4327 12.5569 53.0983 14.1891 53.0962 16.1666V16.21C53.0942 18.1872 54.5326 19.8437 56.5529 19.8457C58.6594 19.848 59.9939 18.2159 59.9959 16.2598ZM50.4955 16.2498V16.207C50.4987 12.9615 53.081 10.2771 56.5629 10.2809C60.0448 10.2844 62.6001 12.9308 62.5966 16.1766V16.2196C62.5931 19.4435 60.0111 22.1276 56.5078 22.1241C53.047 22.1203 50.492 19.4739 50.4955 16.2498Z" fill="#FFF"/>
              <path d="M64.8155 10.5261L67.4165 10.5287L67.4147 12.2698C68.1466 11.2172 69.2007 10.294 70.9632 10.2958C73.5211 10.2984 75.0023 12.0193 74.9994 14.642L74.9921 21.8853L72.3914 21.8827L72.3978 15.4345C72.3999 13.6717 71.5195 12.6606 69.9721 12.6589C68.4677 12.6574 67.4133 13.7098 67.4115 15.4723L67.4048 21.8774L64.8038 21.8748L64.8155 10.5261Z" fill="#FFF"/>
              <path d="M78.2405 18.7506L78.2467 12.7752L76.8062 12.7737L76.8085 10.5387L78.249 10.5401L78.2522 7.42294L80.8526 7.42558L80.8494 10.5428L83.9019 10.546L83.8995 12.781L80.8471 12.7778L80.8412 18.3445C80.8403 19.3547 81.356 19.764 82.2372 19.7648C82.8176 19.7654 83.3335 19.6368 83.8494 19.3796L83.8471 21.5077C83.202 21.8725 82.4927 22.0866 81.5039 22.0857C79.5905 22.0837 78.2379 21.244 78.2405 18.7506Z" fill="#FFF"/>
              <path d="M93.5686 15.4348C93.3981 13.7584 92.4108 12.4462 90.6908 12.4444C89.1006 12.4427 87.9817 13.6667 87.7435 15.4286L93.5686 15.4348ZM85.1634 16.2645V16.2217C85.1669 12.9759 87.4693 10.3128 90.7148 10.3163C94.326 10.3201 96.15 13.1593 96.1465 16.4265C96.1462 16.6629 96.1248 16.899 96.1031 17.1571L87.7629 17.1486C88.0409 18.997 89.3511 20.0303 91.0271 20.032C92.2954 20.0332 93.1989 19.5612 94.1024 18.6812L95.627 20.0367C94.5507 21.3252 93.0665 22.1619 90.9823 22.1599C87.6937 22.1563 85.1596 19.7681 85.1634 16.2645Z" fill="#FFF"/>
              <path d="M98.3443 10.5609L100.945 10.5636L100.944 12.3047C101.675 11.252 102.729 10.3289 104.491 10.3307C107.05 10.3333 108.531 12.0542 108.528 14.6769L108.52 21.9202L105.92 21.9176L105.927 15.4693C105.928 13.7065 105.048 12.6955 103.501 12.6937C101.996 12.6923 100.942 13.7446 100.94 15.5071L100.934 21.9123L98.3326 21.9097L98.3443 10.5609Z" fill="#FFF"/>
              <path d="M111.769 18.7854L111.775 12.8101L110.335 12.8086L110.337 10.5735L111.777 10.575L111.78 7.45781L114.381 7.46045L114.378 10.5776L117.43 10.5809L117.428 12.8159L114.376 12.8127L114.37 18.3794C114.369 19.3898 114.884 19.7988 115.765 19.7997C116.346 19.8003 116.862 19.6717 117.378 19.4144L117.375 21.5426C116.731 21.9073 116.021 22.1215 115.032 22.1206C113.119 22.1186 111.766 21.2789 111.769 18.7854Z" fill="#FFF"/>
              <path d="M119.794 10.5832L122.395 10.5861L122.383 21.9346L119.782 21.9319L119.794 10.5832ZM119.691 6.37003L122.485 6.37296L122.482 8.84473L119.688 8.8418L119.691 6.37003Z" fill="#FFF"/>
              <path d="M125.36 10.5891L127.961 10.5917L127.96 12.3328C128.691 11.2802 129.745 10.357 131.508 10.3588C134.066 10.3614 135.547 12.0823 135.544 14.705L135.537 21.9483L132.936 21.9457L132.943 15.4975C132.945 13.7347 132.064 12.7236 130.517 12.7219C129.012 12.7204 127.958 13.7727 127.956 15.5352L127.95 21.9404L125.349 21.9378L125.36 10.5891Z" fill="#FFF"/>
              <path d="M147.127 16.3503L147.128 16.3078C147.13 14.3083 145.691 12.6519 143.649 12.6498C141.564 12.6475 140.229 14.2796 140.227 16.2574V16.3005C140.225 18.2777 141.664 19.9342 143.684 19.9365C145.791 19.9386 147.125 18.3064 147.127 16.3503ZM137.627 16.3406V16.2979C137.63 13.0521 140.213 10.3679 143.694 10.3714C147.177 10.3752 149.731 13.0213 149.728 16.2671V16.3105C149.725 19.534 147.143 22.2185 143.639 22.2147C140.179 22.2111 137.624 19.5647 137.627 16.3406Z" fill="#FFF"/>
            </svg>
        </a>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className={style.toggler}>
        <span className={style.togglerLine} />
        <span className={style.togglerLine} />
        <span className={style.togglerLine} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" className={style.collapseWrapper}>
        <Nav className={style.nav}>
          <Nav.Link className={style.link} href="https://www.kontentino.com/why-kontentino" target='_blank'>WHY KONTENTINO</Nav.Link>
          <NavDropdown 
            className={style.linkParent} 
            title={(
              <span className={style.link}>
                SOLUTIONS
              </span>
            )} 
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/mobile-app" target='_blank'>
              Mobile app
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/planning-and-collaboration" target='_blank'>
              Planning and collaboration
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/client-approvals" target='_blank'>
              Client approvals
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/fb-ads-manager" target='_blank'>
              Managing Facebook ads
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/ig-creator-studio" target='_blank'>
              IG Creator Studio
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/insights-and-reporting" target='_blank'>
              Insights and reporting
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/enterprise" target='_blank'>
              Enterprise
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className={style.link} href="https://www.kontentino.com/pricing" target='_balnk'>PRICING</Nav.Link>
          <NavDropdown
            className={style.linkParent}
            title={(
              <span className={style.link}>
                RESOURCES
              </span>
            )}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/tutorials" target='_balnk'>
              Tutorials
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/blog/" target='_balnk'>
              Blog
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/ebooks" target='_balnk'>
              Ebooks
            </NavDropdown.Item>
            <NavDropdown.Item className={style.subItem} href="https://www.kontentino.com/roadmap" target='_balnk'>
              Product road map
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className={style.link} href="https://www.kontentino.com/faqs" target='_blank'>FAQs</Nav.Link>
          <Nav.Link className={style.link + " " + style.mlAuto} href="https://www.kontentino.com/login" target='_blank'>LOGIN</Nav.Link>
          <a href='https://www.kontentino.com/signup' target='_balnk' rel='noreferrer' className={classes(style.button, style.varB)}>
            Sign up
          </a>
        </Nav>
        </Navbar.Collapse>
      </Navbar>
  </div>
)};

export default React.memo(Navigation);
