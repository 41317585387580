import React from 'react';
import style from './Body.module.css';

const Body = ({children}: any) => {
  return (
    <div className={style.landingPage}>
      {children}
    </div>
  )
}

export default React.memo(Body);
