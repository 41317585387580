import {Component} from 'react';
import style from './Grid.module.css';

const GridSticky = ({ children }: any) => {
    return (
        <div className={style.stickyWrapper}>
            <div className={style.stickyContent}>
                {children}
            </div>
        </div>
    )
}

const GridForm = ({ children }: any) => {
    return (
        <div className={style.formBg}>
            <div className={style.container}>
                <div className={style.box}>
                    {children}
                </div>
            </div>
        </div>
    )
}

const GridText = ({ children }: any) => {
    return (
        <div className={style.container}>
            {children}
        </div>
    )
}

export default class Grid extends Component {
    static Sticky = GridSticky;
    static Form = GridForm;
    static Text = GridText;

    render() {
        return (
            <div className={style.grid}>
                {this.props.children}
            </div>
        );
    }
}
