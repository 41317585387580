import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import style from './SubscribeForm.module.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import imgIcon1 from '../images/subscribe/schedule-facebook-image-posts.svg';
import imgIcon2 from '../images/subscribe/schedule-social-media-posts.svg';
import imgIcon3 from '../images/subscribe/utm-tag-management-template.svg';
import imgIcon4 from '../images/subscribe/scheduling-video-posts-with-kontentino.svg';
import imgIcon5 from '../images/subscribe/boost-facebook-user-engagement.svg';

const SubscribeForm = ({ children }: any) => {
  const [workEmail, setWorkEmail] = useState('');
  const [hasError, setHasError] = useState(false);

  const onWorkEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkEmail(event.target.value);
  };

  const onFormSubmit = (event: React.SyntheticEvent) => {

    event.preventDefault();
    setHasError(false);

    let form = event.target as HTMLFormElement;

    fetch("https://www.kontentino.com/api/subscribe", {
      method: "POST",
      mode: 'no-cors',
      body: JSON.stringify({
        'email': workEmail,
        'audience': 'utm_builder_subscribers',
        'tags': [form.getAttribute("data-tag")],
      })
    })
      .then(() => {

        const source = form.getAttribute("data-source");

        if (source !== null) {

          let link = document.createElement('a');
          link.target = '_blank';
          link.href = source;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setWorkEmail('');
        }

      })
      .catch(() => setHasError(true));

    return false;

  };

  return (
    <div className={style.subscribeForm}>
      <div className={style.content}>

        {children}

        <Form id="subscribe-form" onSubmit={onFormSubmit} data-source="https://docs.google.com/spreadsheets/d/1W5BdmeqKpyqT_tuZGB0jB7hzEHv_sIyQe3TqWBvg4v0/template/preview" data-tag="xls_template">
          <Form.Group>
            <Form.Label className={style.label}>Work e-mail</Form.Label>
            <Form.Control required type="email" placeholder="" value={workEmail} onChange={onWorkEmailChange} className={style.input} />

            {hasError && (
              <small className="form-text text-danger">
                An error occurred. Please try it again later.
              </small>
            )}

          </Form.Group>

          <Button type="submit" className={style.button}>Get it free</Button>
        </Form>
      </div>

      <img src={imgIcon1} width="80" height="80" className={style.icon1} alt="schedule facebook image posts" />
      <img src={imgIcon2} width="92" height="85" className={style.icon2} alt="schedule social media posts" />
      <img src={imgIcon3} width="57" height="57" className={style.icon3} alt="utm tag management template" />
      <img src={imgIcon4} width="80" height="68" className={style.icon4} alt="scheduling video posts with kontentino" />
      <img src={imgIcon5} width="45" height="45" className={style.icon5} alt="boost facebook user engagement" />
    </div>
  )
}

export default React.memo(SubscribeForm);
