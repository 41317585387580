import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Body from '../components/Body';
import FormatedText from '../components/FormatedText';
import SubscribeForm from '../components/SubscribeForm';
import Grid from '../components/Grid';
import Alert from 'react-bootstrap/Alert';
import TextFeatures from '../components/TextFeatures';
import featureImg1 from '../images/features/Agency_client_collaboration_with_LinkedIn_tool.svg';
import featureImg2 from '../images/features/LinkedIn_collaboration-and-approvals_tool.svg';
import featureImg3 from '../images/features/Kontentino_LinkedIn_inspirations_calendar_tool.svg';

const Home: React.FC = () => {
  const [url, setUrl] = useState('');
  const [finalUrl, setFinalUrl] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');
  const [hasError, setHasError] = useState(false);

  const onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    update({ url: event.target.value });
  };

  const onUtmSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUtmSource(event.target.value);
    update({ utmSource: event.target.value });
  };

  const onUtmMediumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUtmMedium(event.target.value);
    update({ utmMedium: event.target.value });
  };

  const onUtmCampaignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUtmCampaign(event.target.value);
    update({ utmCampaign: event.target.value });
  };

  const onUtmTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUtmTerm(event.target.value);
    update({ utmTerm: event.target.value });
  };

  const onUtmContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUtmContent(event.target.value);
    update({ utmContent: event.target.value });
  };

  const update = (utm: { [x: string]: string }) => {
    const params = {
      url,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      ...utm,
    };

    try {
      const u = new URL(params.url.toLowerCase().startsWith('http') ? params.url : `https://${params.url}`);

      if (params.utmSource.length > 0) {
        u.searchParams.set('utm_source', params.utmSource);
      } else {
        u.searchParams.delete('utm_source');
      }

      if (params.utmMedium.length > 0) {
        u.searchParams.set('utm_medium', params.utmMedium);
      } else {
        u.searchParams.delete('utm_medium');
      }

      if (params.utmCampaign.length > 0) {
        u.searchParams.set('utm_campaign', params.utmCampaign);
      } else {
        u.searchParams.delete('utm_campaign');
      }

      if (params.utmTerm.length > 0) {
        u.searchParams.set('utm_term', params.utmTerm);
      } else {
        u.searchParams.delete('utm_term');
      }

      if (params.utmContent.length > 0) {
        u.searchParams.set('utm_content', params.utmContent);
      } else {
        u.searchParams.delete('utm_content');
      }

      setFinalUrl(u.toString());
      setHasError(false);
    } catch (err) {
      setHasError(true);
      setFinalUrl(params.url);
    }
  }

  const onCopyUrlClick = () => {
    const el = document.createElement('textarea');
    el.value = finalUrl;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  return (
    <Body>

      <Grid>
        <Grid.Sticky>
          <SubscribeForm>
            <h1>Free UTM tag management template</h1>
            <p>Use our proven template to generate, store and share your URL tags with ease.</p>
          </SubscribeForm>
        </Grid.Sticky>

        <Grid.Form>
          <h2>UTM Tag Builder</h2>
          <Form>
            <Form.Group controlId="newURLAddress">
              <Form.Label>URL Address</Form.Label>
              <Form.Control required type="text" placeholder="https://" value={url} onChange={onUrlChange} />
              <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
              <Form.Text className="text-muted" style={{ wordBreak: 'break-all' }}>{finalUrl}</Form.Text>
            </Form.Group>

            <Form.Group controlId="newUTMSource">
              <Form.Label>UTM Source</Form.Label>
              <Form.Control type="text" placeholder="" value={utmSource} onChange={onUtmSourceChange} />
              <Form.Text className="text-muted">The referrer: (e.g. <code>google</code>, <code>newsletter</code>)</Form.Text>
            </Form.Group>

            <Form.Group controlId="newUTMMedium">
              <Form.Label>UTM Medium</Form.Label>
              <Form.Control required type="text" placeholder="" value={utmMedium} onChange={onUtmMediumChange} />
              <Form.Text className="text-muted">Marketing medium: (e.g. <code>cpc</code>, <code>banner</code>, <code>email</code>)</Form.Text>
            </Form.Group>

            <Form.Group controlId="newUTMCampaign">
              <Form.Label>UTM Campaign</Form.Label>
              <Form.Control required type="text" placeholder="" value={utmCampaign} onChange={onUtmCampaignChange} />
              <Form.Text className="text-muted">Product, promo code, or slogan: (e.g. <code>spring_sale</code>)</Form.Text>
            </Form.Group>

            <Form.Group controlId="newUTMCampaign">
              <Form.Label>UTM Term</Form.Label>
              <Form.Control required type="text" placeholder="" value={utmTerm} onChange={onUtmTermChange} />
              <Form.Text className="text-muted">Identify the paid keywords</Form.Text>
            </Form.Group>

            <Form.Group controlId="newUTMCampaign">
              <Form.Label>Campaign content</Form.Label>
              <Form.Control required type="text" placeholder="" value={utmContent} onChange={onUtmContentChange} />
              <Form.Text className="text-muted">Use to differentiate ads</Form.Text>
            </Form.Group>

            <Form.Group controlId="generatedUrl">
              <Form.Label>Generated URL</Form.Label>
              <Form.Control as="textarea" value={finalUrl} rows={3} readOnly />
            </Form.Group>

            <div className="text-right mb-4">
              <Button onClick={onCopyUrlClick}>Copy URL</Button>
            </div>

            {hasError && (
              <Alert
                variant='danger'
              >
                Invalid Url
              </Alert>
            )}
          </Form>
        </Grid.Form>

        <Grid.Text>
          <FormatedText>
            <h3>UTM tags – what are they?</h3>
            <p>
              UTM codes/tags/parameters (there's like a million different terms that marketers use when 
              referring to UTM tags) are little pieces of code that you add to your URLs. This enables 
              you to track and analyse how your content is doing in Google Analytics or other analytical tools.
            </p>
            <p>
              The way you will use UTM codes in your campaigns will differ depending on what you want to track. 
              There is no single right template that will fit all businesses. Take the time to think about all 
              the activities you need to track and imagine different ways how UTM tags could help you.
            </p>

            <h3>How should they be used?</h3>
            <p>
              Source – UTM code "source" acts like the referrer. It tells you where your traffic comes from. For example, Google, Facebook etc. 
            </p>
            <p>
              Medium – UTM code "medium" tells you how your traffic gets to you. There are many ways to 
              look at this parameter, for example, a Facebook campaign could be labelled as medium "display" or 
              "paid-social" or even "retargeting", if that's the nature of the campaign. The safest option 
              is probably to tag all your social media links "social" to make your life easier.
            </p>
            <p>
              Campaign – UTM code "campaign" can be used to name your campaigns so you know what campaign/effort brought you that traffic.
            </p>
            <p>
              Term – UTM code "term" is helpful when you need to track keywords used in your paid campaigns.<br />
              Content – UTM code "content" can be used to tell you more details about the 
              campaign, or when A/B testing two links with different preview images.
            </p>

            <h3>Important things to keep in mind</h3>
            <p>There are some important things to keep in mind (if you want to rule the UTMs and not let them rule over you).</p>
            <ul>
              <li>
                Organize your UTM links – after creating dozens of links and UTM definitions, things might get messy 
                if not properly organized. One way to keep the order is to create a spreadsheet where you document 
                all your links and definitions. You can download our proven template on top of this page.
              </li>
              <li>
                Consistency is key – think about all the different options of using UTM 
                codes to track your campaigns and stick to the one you will choose. 
              </li>
              <li>
                Make sure your team knows the UTM tagging process in your company. Since there are so many ways to use UTMs, 
                it's best if there's a general understanding or a template that will guide the team.
              </li>
              <li>
                Lower-case letters are your best friends from now on. UTM parameters are case-sensitive. 
                So, if you spelled “Facebook” with a capital “F” in one link and a lower-case “f” 
                in another, they would track separately, stopping you from gaining the full picture.
              </li>
            </ul>
          </FormatedText>
        </Grid.Text>
      </Grid>

      <TextFeatures>
        <h2>Using UTM tags for social <br /> media? Try Kontentino</h2>
        <p>The best social media scheduler for team collaboration and client approvals</p>

        <Row>
          <Col className="col-12 col-md-4 text-center">
            <img src={featureImg1} alt="Agency client collaboration with LinkedIn tool" />
            <p>Keep your team organized – No more miscommunications. Exchange feedback with designers, copywriters and translators to create the perfect post in minutes</p>
          </Col>
          <Col className="col-12 col-md-4 text-center">
            <img src={featureImg2} alt="LinkedIn collaboration and approvals tool" />
            <p>1-click approval process – Make sure your content is approved before publishing through a fast and straightforward process that both teams and clients love</p>
          </Col>
          <Col className="col-12 col-md-4 text-center">
            <img src={featureImg3} alt="Kontentino LinkedIn inspirations calendar tool" />
            <p>All posts in one place –  Schedule all post formats on Facebook, Instagram, Linkedin, Pinterest and Twitter from a single calendar, divided by brand or client</p>
          </Col>
        </Row>
      </TextFeatures>

    </Body>
  );
};

export default Home;
