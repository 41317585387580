import React from 'react';
import style from './FormatedText.module.css';

const FormatedText = ({ children }: any) => (
  <div className={style.formatedText}>
    {children}
  </div>
);

export default React.memo(FormatedText);
